import {Link} from 'react-router-dom';
import "../stylesheet/aboutus.css"
import { useEffect, useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import language from '../data/landuage';

const AboutUs = () => {
    const [focused, setFocused] = useState({
        name: false, surname: false, email: false, phone: false, message: false
    })
    const [formData, setFormData] = useState({name: "", surname: "", email: "", phone: "", message: ""});
    const [errors, setErrors] = useState({name: false, surname: false, email: false, phone: false, message: false});
    const [messageText, setMessageText] = useState("");
    const lang = useSelector((state) => state.user.language);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // Optional: for smooth scrolling
        });
    }, [])

    return(
        <>
            <div className='about-us-banner'>
                <div className='about-us-banner-content'>
                    <h3 className='about-us-title'>{language.aboutUs[lang].title}</h3>
                    <p className='about-us-page-details'>
                        {language.aboutUs[lang].description}
                    </p>
                </div>
            </div>
            <div className="about-us-container">
                <div className='about-us-content'>
                <div className="why-choose-us-container">
                    <div className="why-us-container-background"></div>
                    <h3 className="why-choose-us-title">{language.aboutUs[lang].servicesTitle}?</h3>
                    <div className="why-choose-us-list">
                        <div className="why-choose-us-item">
                            <img src="/static/icons/service-icon-1.png" alt="price"/>
                            <p className="why-choose-us-item-title">{language.aboutUs[lang].vehicleSearch.title}</p>
                            <p className="why-choose-us-item-description">{language.aboutUs[lang].vehicleSearch.description}</p>
                        </div>
                        <div className="why-choose-us-item">
                            <img src="/static/icons/service-icon-2.svg" alt="price"/>
                            <p className="why-choose-us-item-title">{language.aboutUs[lang].specialServices.title}</p>
                            <p className="why-choose-us-item-description">{language.aboutUs[lang].specialServices.description}</p>
                        </div>
                        <div className="why-choose-us-item">
                            <img src="/static/icons/service-icon-3.svg" alt="price"/>
                            <p className="why-choose-us-item-title">{language.aboutUs[lang].vehicleCutting.title}</p>
                            <p className="why-choose-us-item-description">{language.aboutUs[lang].vehicleCutting.description}</p>
                        </div>
                    </div>
                </div>
                </div>
                <div className='about-us-page-banners-container'>
                    <div className='banner-first-col'>
                        <div style={{backgroundColor: "#405FF2", color: "white", height: "300px", padding: "42px 32px", fontSize: "30px", fontWeight: "500"}}>
                            4 <br /> წელი ბაზარზე
                        </div>
                        <div style={{backgroundImage: `url('/static/banner-1.png')`, height: "217px", marginTop: "29px"}}>

                        </div>
                    </div>
                    <div className='banner-second-col' style={{backgroundImage: `url('/static/banner-2.png')`, height: "546px"}}>

                    </div>
                    <div className='banner-third-col'>
                        <div style={{backgroundImage: `url('/static/banner-3.png')`, height: "300px"}}>

                        </div>
                        <div className='banner-with-two-col'>
                            <div style={{backgroundImage: `url('/static/banner-4.png')`, color: "white", width: "208px", height: "217px", marginTop: "29px", padding: "42px 32px", fontSize: "30px", fontWeight: "500"}}>

                            </div>
                            <div style={{backgroundImage: `url('/static/banner-5.png')`, color: "white", width: "320px", height: "217px", marginTop: "29px", padding: "42px 32px", fontSize: "30px", fontWeight: "500"}}>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='lead-form-container'>
                <h3 className='lead-form-title'>{language.aboutUs[lang].leadForm.title}</h3>
                <div className='lead-form-col'>
                    <div className="form-group form-field-divided">
                        <label >{language.aboutUs[lang].leadForm.name}</label>
                        <input 
                            className="form-control" 
                            onBlur={() => setFocused((prev) => ({...prev, name: false}))}
                            onFocus={() => setFocused((prev) => ({...prev, name: true}))}
                            onChange={(e) => {
                                setFormData((prev) => ({...prev, name: e.target.value}));
                                setErrors((prev) => ({...prev, name: false}));
                            }}
                            value={formData.name}
                            placeholder={language.aboutUs[lang].leadForm.name}
                        />
                        {errors.name && <p className="error-text">{messageText}</p>}
                    </div>
                    <div className="form-group form-field-divided">
                        <label>{language.aboutUs[lang].leadForm.surname}</label>
                        <input 
                            className="form-control" 
                            onBlur={() => setFocused((prev) => ({...prev, surname: false}))}
                            onFocus={() => setFocused((prev) => ({...prev, surname: true}))}
                            onChange={(e) => {
                                setFormData((prev) => ({...prev, surname: e.target.value}));
                                setErrors((prev) => ({...prev, surname: false}));
                            }}
                            value={formData.surname}
                            placeholder={language.aboutUs[lang].leadForm.surname}
                        />
                        {errors.surname && <p className="error-text">{messageText}</p>}
                    </div>
                    <div className="form-group form-field-divided">
                        <label>{language.aboutUs[lang].leadForm.email}</label>
                        <input 
                            className="form-control" 
                            onBlur={() => setFocused((prev) => ({...prev, email: false}))}
                            onFocus={() => setFocused((prev) => ({...prev, email: true}))}
                            onChange={(e) => {
                                setFormData((prev) => ({...prev, email: e.target.value}));
                                setErrors((prev) => ({...prev, email: false}));
                            }}
                            value={formData.email}
                            placeholder={language.aboutUs[lang].leadForm.email}
                        />
                        {errors.email && <p className="error-text">{messageText}</p>}
                    </div>
                    <div className="form-group form-field-divided">
                        <label >{language.aboutUs[lang].leadForm.phone}</label>
                        <input 
                            className="form-control" 
                            onBlur={() => setFocused((prev) => ({...prev, phone: false}))}
                            onFocus={() => setFocused((prev) => ({...prev, phone: true}))}
                            onChange={(e) => {
                                setFormData((prev) => ({...prev, phone: e.target.value}));
                                setErrors((prev) => ({...prev, phone: false}));
                            }}
                            value={formData.phone}
                            placeholder={language.aboutUs[lang].leadForm.phone}
                        />
                        {errors.phone && <p className="error-text">{messageText}</p>}
                    </div>
                    <span className='comment-head-title'>{language.aboutUs[lang].leadForm.message}</span>
                    <textarea placeholder='Leave us a message' onChange={(e) => setFormData((prev) => ({...prev, message: e.target.value}))} className='send-message-text' value={formData.message}>

                    </textarea>
                    <button onClick={() => {
                        axios.post('/api/lead-form', formData)
                            .then((res) => {
                                alert("შეტყობინება გაგზავნილია");
                                setFormData({name: "", surname: "", email: "", phone: "", message: ""});
                            })
                            .catch((err) => {
                                
                            })
                        
                    }} className='lead-form-submit-button'>{language.standard[lang].send}</button>
                </div>
                <div className='lead-form-col'>
                    <img src="/static/lead-form-banner.png" className='lead-form-banner' alt='lead-form-banner'/>
                    <div className='lead-form-details-box'>
                        <div style={{width: "32px", height: "32px", border: "1px solid #405FF2", borderRadius: "50%"}}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                <path d="M8.00004 7.66659C7.55801 7.66659 7.13409 7.49099 6.82153 7.17843C6.50897 6.86587 6.33337 6.44195 6.33337 5.99992C6.33337 5.55789 6.50897 5.13397 6.82153 4.82141C7.13409 4.50885 7.55801 4.33325 8.00004 4.33325C8.44207 4.33325 8.86599 4.50885 9.17855 4.82141C9.49111 5.13397 9.66671 5.55789 9.66671 5.99992C9.66671 6.21879 9.6236 6.43552 9.53984 6.63773C9.45608 6.83993 9.33332 7.02367 9.17855 7.17843C9.02379 7.3332 8.84006 7.45596 8.63785 7.53972C8.43564 7.62348 8.21891 7.66659 8.00004 7.66659ZM8.00004 1.33325C6.76236 1.33325 5.57538 1.82492 4.70021 2.70009C3.82504 3.57526 3.33337 4.76224 3.33337 5.99992C3.33337 9.49992 8.00004 14.6666 8.00004 14.6666C8.00004 14.6666 12.6667 9.49992 12.6667 5.99992C12.6667 4.76224 12.175 3.57526 11.2999 2.70009C10.4247 1.82492 9.23772 1.33325 8.00004 1.33325Z" fill="#405FF2"/>
                            </svg>
                        </div>
                        <span className='lead-form-details-text'>აღმაშენებლის ხეივანი N19, თბილისი</span>
                    </div>
                    <div className='lead-form-details-box'>
                        <div style={{width: "32px", height: "32px", border: "1px solid #405FF2", borderRadius: "50%"}}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                <path d="M12.6667 7.96655C12.6667 6.66655 12.2138 5.56366 11.308 4.65789C10.4027 3.75255 9.3 3.29989 8 3.29989V1.96655C8.83333 1.96655 9.614 2.12477 10.342 2.44122C11.0696 2.75811 11.7029 3.18611 12.242 3.72522C12.7807 4.26389 13.2084 4.89722 13.5253 5.62522C13.8418 6.35278 14 7.13322 14 7.96655H12.6667ZM10 7.96655C10 7.411 9.80556 6.93878 9.41667 6.54989C9.02778 6.161 8.55556 5.96655 8 5.96655V4.63322C8.92222 4.63322 9.70844 4.95811 10.3587 5.60789C11.0084 6.25811 11.3333 7.04433 11.3333 7.96655H10ZM13.3 13.9999C11.8667 13.9999 10.4696 13.6803 9.10867 13.0412C7.74733 12.4026 6.54178 11.5581 5.492 10.5079C4.44178 9.45811 3.59733 8.25255 2.95867 6.89122C2.31956 5.53033 2 4.13322 2 2.69989C2 2.49989 2.06667 2.33322 2.2 2.19989C2.33333 2.06655 2.5 1.99989 2.7 1.99989H5.4C5.55556 1.99989 5.69444 2.04989 5.81667 2.14989C5.93889 2.24989 6.01111 2.37766 6.03333 2.53322L6.46667 4.86655C6.48889 5.02211 6.48622 5.16366 6.45867 5.29122C6.43067 5.41922 6.36667 5.53322 6.26667 5.63322L4.66667 7.26655C5.13333 8.06655 5.71667 8.81655 6.41667 9.51655C7.11667 10.2166 7.88889 10.8221 8.73333 11.3332L10.3 9.76655C10.4 9.66655 10.5307 9.59144 10.692 9.54122C10.8529 9.49144 11.0111 9.47766 11.1667 9.49989L13.4667 9.96655C13.6222 9.99989 13.75 10.0748 13.85 10.1912C13.95 10.3081 14 10.4443 14 10.5999V13.2999C14 13.4999 13.9333 13.6666 13.8 13.7999C13.6667 13.9332 13.5 13.9999 13.3 13.9999Z" fill="#405FF2"/>
                            </svg>
                        </div>
                        <a href='tel:+0322111122' className='lead-form-details-text'>+032 211 11 22</a>
                    </div>
                    <div className='lead-form-details-box'>
                        <div style={{width: "32px", height: "32px", border: "1px solid #405FF2", borderRadius: "50%"}}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                <path d="M14 2L2 7.02V7.66667L6.56 9.44L8.33333 14H8.97333L14 2Z" fill="#405FF2"/>
                            </svg>
                        </div>
                        <a href='mailto:Info@Carspace.ge' className='lead-form-details-text'>Info@Carspace.ge</a>
                    </div>
                </div>
            </div>  
        </>
    )
}

export default AboutUs