import { useSelector } from "react-redux"
import "./WhyUs.css"
import language from "../../../data/landuage";

const WhyUs = () => {
    const lang = useSelector((state) => state.user.language);
    
    return(
        <div className="why-choose-us-container">
            <div className="why-us-container-background"></div>
            <h3 className="why-choose-us-title">{language.home[lang].whyCarspace.title}?</h3>
            <div className="why-choose-us-list">
                <div className="why-choose-us-item">
                    <img src="/static/icons/price.png" alt="price"/>
                    <p className="why-choose-us-item-title">{language.home[lang].whyCarspace.price.title}</p>
                    <p className="why-choose-us-item-description">{language.home[lang].whyCarspace.price.description}</p>
                </div>
                <div className="why-choose-us-item">
                    <img src="/static/icons/support.png" alt="price"/>
                    <p className="why-choose-us-item-title">{language.home[lang].whyCarspace.support.title}</p>
                    <p className="why-choose-us-item-description">{language.home[lang].whyCarspace.support.description}</p>
                </div>
                <div className="why-choose-us-item benefits">
                    <img src="/static/icons/benefits.png" alt="price"/>
                    <p className="why-choose-us-item-title">{language.home[lang].whyCarspace.benefits.title}</p>
                    <p className="why-choose-us-item-description">{language.home[lang].whyCarspace.benefits.description}</p>
                </div>
                <div className="why-choose-us-item">
                    <img src="/static/icons/clients.png" alt="price"/>
                    <p className="why-choose-us-item-title">{language.home[lang].whyCarspace.safety.title}</p>
                    <p className="why-choose-us-item-description">{language.home[lang].whyCarspace.safety.description}</p>
                </div>
            </div>
        </div>
    )
}

export default WhyUs