/* eslint-disable */
import { useEffect } from "react";
import "../stylesheet/Loading.css"
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { usersActions } from "../store/user";

const Loading = ({isLoading}) => {
    const dispatch = useDispatch();
    const token = useSelector((state) => state.user.token)

    useEffect(() => {
        axios.post(`/api/auction/copart/recomended-lots`, {token: token == null ? "unset" : token})
            .then((res) => {
                const t = res.data.token;
                if(token == null) dispatch(usersActions.setToken(t));
                isLoading(false);
            })
            .catch(() => {
                isLoading(false);
            })

        axios.get('/api/user')
            .then((res) => {
                const data = res.data.data;
                dispatch(usersActions.changeIsLoggedIn(true));
                dispatch(usersActions.saveUserData(data));
                console.log("success")
            })
            .catch((err) => {
                console.log(err)
                dispatch(usersActions.changeIsLoggedIn(false));
            })
    }, [])

    return(
        <div className="loading-page-container">
            <div className="loading-page-left-side">
                <h2>CarSpace</h2>
            </div>
            <div className="loading-page-right-side">
                <div className="loader"></div>
            </div>
        </div>
    )
}

export default Loading